import React from 'react';
import Vacances from './component/Vacances';

function App() {
  return (
    <div className="App">
      <Vacances />
    </div>
  );
}

export default App;
